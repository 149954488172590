import { EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA } from "domain/entity/SupplementaryInvoice/SupplementaryInvoiceSearchCriteria";
import moment from "moment";
import { useSupplementaryInvoiceVM } from "presentation/hook/SupplementaryInvoice/useSupplementaryInvoiceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useSupplementaryInvoiceTracked } from "presentation/store/SupplementaryInvoice/SupplementaryInvoiceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import SupplementaryInvoiceSearchPanel from "./LeftPanel/SupplementaryInvoiceSearchPanel";
import SupplementaryInvoiceTitleBar from "./SupplementaryInvoiceTitleBar";
import SupplementaryInvoiceTablePanel from "./Table/SupplementaryInvoiceTablePanel";

const SupplementaryInvoiceMaintenance:React.FC = () => {
    const [supplementaryInvoiceState] = useSupplementaryInvoiceTracked();
    const { isShowCriteriaPanel,isBackFromDetail,searchCriteria,isAllowAutoSearch } = supplementaryInvoiceState;
    const supplementaryInvoiceVM = useSupplementaryInvoiceVM();
    const [isLoading, setIsLoading] = useState(true);
    const [anaInfoState] = useANAInfoTracked();

    useEffect(() => {
        const initialScreen = async() => {   
            try {        
                let newSearchCriteria = null; 
                if(isBackFromDetail){
                    newSearchCriteria = {...searchCriteria};
                }else{
                    newSearchCriteria = {...EMPTY_SUPPLEMENTARY_INVOICE_SEARCH_CRITERIA} 
                        newSearchCriteria = { ...newSearchCriteria, 
                            createdDateFrom: moment().subtract(10, "days").startOf("day").toDate(),
                            createdDateTo: moment().endOf("day").toDate(), 
                            createdBys:[anaInfoState.userName],
                        };
                }
                const results = await Promise.allSettled([
                    supplementaryInvoiceVM.loadDropdownOption(),
                    supplementaryInvoiceVM.onSearch(newSearchCriteria),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                      } else if (index === 1 && result.status === 'rejected') {

                      }
                })
            } catch(error) {
                setIsLoading(false)
            }
        }
        isAllowAutoSearch && initialScreen().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [anaInfoState.userName, isAllowAutoSearch, isBackFromDetail, searchCriteria, supplementaryInvoiceVM])

    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
   
    return <>
    <div className={`main-comp-wrapper${(isShowCriteriaPanel) ? '' : ' im-hide-side-form-draggable'}`}>
    {/* </div>
        <div className="main-comp-wrapper"> */}
           <SupplementaryInvoiceTitleBar/>

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<SupplementaryInvoiceSearchPanel/>}
                rightChildren={<SupplementaryInvoiceTablePanel/>} 
                />
        </div>
    </>
}
export default memo(SupplementaryInvoiceMaintenance);